<template>
  <div class="StageMapBox">
    <div
      class="StageMap"
      :class="'StageMap' + mapStyle"
      :style="`background-image: url(${mapStyle == 1 ? bg1 : bg2})`"
    >
      <div class="mapTop">
        <div class="left">
          <div @click="$router.back()">
            <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          </div>
          <img
            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/map-title-icon.png"
            class="title-icon"
            alt=""
          />
        </div>
        <div class="right" @click="dialogVisible.show = true">
          <img
            :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/stageMap/button${mapStyle}.png`"
            alt=""
          />
        </div>
      </div>
      <div
        class="container"
        :class="mapStyle === 1 ? 'container1' : 'container2'"
      >
        <div
          class="item"
          v-for="(item, index) in list"
          :key="item.id"
          @click="toDetail(item)"
        >
          <div class="stageIcon">
            <template v-if="mapStyle === 2">
              <img
                :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/stageMap/map2-level${
                  index + 1
                }.png`"
                v-if="item.isLocked"
                alt=""
              />
              <img
                v-else
                :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/stageMap/brightIcon.png`"
                class="brightIcon"
                alt=""
              />
            </template>
            <template v-else>
              <img
                :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/StageMap/level${
                  index + 1
                }-1.png`"
                v-if="item.isLocked"
                alt=""
              />
              <img
                v-else
                :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/StageMap/level${
                  index + 1
                }.png`"
                alt=""
              />
            </template>
          </div>
          <template v-if="mapStyle === 2">
            <div class="content">
              <p class="name">{{ item.stageName }}</p>
              <div class="studyProcess">
                <!-- 四个边角 -->
                <i class="border_corner border_corner_left_top"></i>
                <i class="border_corner border_corner_right_top"></i>
                <i class="border_corner border_corner_left_bottom"></i>
                <i class="border_corner border_corner_right_bottom"></i>
                <template v-if="item.isLocked">
                  <p>{{ item.finishedCount || 0 }}人已完成</p>
                </template>
                <template v-else>
                  <p>进度{{ item.studyProcess || 0 }}%</p>
                </template>
              </div>
            </div>
          </template>
          <template v-if="mapStyle === 1">
            <div class="num" :class="item.isLocked ? 'grey' : 'bright'">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="content">
              <div class="top">
                <div class="Level">
                  <span>Level</span>
                  <template v-for="i in index + 1">
                    <img
                      :key="i"
                      src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/StageMap/levelIcon.png"
                      class="levelIcon"
                      alt=""
                    />
                  </template>
                </div>
                <p class="name">{{ item.stageName }}</p>
              </div>
              <div class="studyProcessIcon">
                <img
                  :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/StageMap/studyProcessIcon${
                    item.isLocked ? '1' : '2'
                  }.png`"
                  alt=""
                />
                <template v-if="item.isLocked">
                  <p>{{ item.finishedCount }}人已完成</p>
                </template>
                <template v-else>
                  <p>进度{{ item.studyProcess || 0 }}%</p>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <mapInfoDialog
        :dialogVisible="dialogVisible"
        :list="list"
        :taskRule="taskRule"
      ></mapInfoDialog>
    </div>
  </div>
</template>

<script>
import mapInfoDialog from "../components/mapInfoDialog.vue";

export default {
  name: "StageMap",
  components: { mapInfoDialog },
  data() {
    return {
      mapStyle: 1,
      list: [],
      dialogVisible: {
        show: false,
      },
      taskRule: {},
      bg1: "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/stageMap/bg1.png",
      bg2: "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/stageMap/bg2.png",
      taskId: "",
      taskInfo: {},
    };
  },
  async created() {
    this.taskId = this.$route.query.id;
    await this.findTaskRule();
    await this.taskDetailWithoutLogin();
  },
  methods: {
    // 获取课程大纲
    async taskDetailWithoutLogin() {
      const params = {
        id: this.taskId,
      };
      await this.$api.courseCenter
        .taskDetailWithoutLogin({ params })
        .then(async (res) => {
          if (res.data) {
            this.list = res.data.taskItemVoList || [];
            if(res.data.type==4){
              this.list = res.data.taskItemVoList[0].children || []
            }
            this.taskInfo = res.data || {};
            await this.taskDetailExt();
          }
        });
    },
    // 二次渲染任务详情 返回学习
    taskDetailExt() {
      const params = {
        id: this.taskId,
      };
      this.$api.learn.taskDetailExts({ params }).then(async (res) => {
        if (res.data) {
          this.taskDetail = {
            ...this.taskDetail,
            learnTotalTime: res.data.learnTotalTime,
            courseNum: res.data.courseNum || 1,
            studyProcess: res.data.studyProcess,
            endTime: res.data.endTime,

            lockCourseRate: res.data.lockCourseRate,
            lockExamCondition: res.data.lockExamCondition,
            isFormCommit: res.data.isFormCommit,
          };
          let tree = [];
          for (let key in res.data.itemMap) {
            tree.push(res.data.itemMap[key]);
          }
          console.log("tree", tree);

          await this.changeTaskTree(tree, this.list);
          console.log("this.treeData", this.list);
        }
      });
    },
    /** 遍历大纲 返回学习相关信息 */
    changeTaskTree(data, data2) {
      const idToItem2Map = {};
      // 构建 data2 的哈希表，以便通过 id 查找项
      loop(data2);
      function loop(node) {
        node.forEach((item2) => {
          idToItem2Map[item2.id] = item2;
          if (item2.children && item2.children.length) {
            loop(item2.children);
          }
        });
      }

      console.log("idToItem2Map", idToItem2Map);
      /* eslint-disable */
      // 递归地更新 data2 中的项
      function updateData2(data) {
        data.forEach((item) => {
          if (idToItem2Map.hasOwnProperty(item.id)) {
            const item2 = idToItem2Map[item.id];
            item2.studyProcess = item.studyProcess;
            item2.studyInfo = item.studyInfo;
            item2.isLocked = item.isLocked;
            item2.finishedCount = item.finishedCount;
            item2.courseId = item.courseId;
          }
          if (item.children && item.children.length) {
            updateData2(item.children);
          }
        });
      }
      // 开始递归更新
      updateData2(data);
      console.log("data2", data2);
      this.list = data2;
    },
    // 任务规则
    async findTaskRule() {
      const res = await this.$api.coursePlay.findTaskRule(this.taskId);
      console.log("res", res);
      if (res.data) {
        this.taskRule = {
          ...res.data.finishedRule,
          ...res.data.supervisionRule,
        };
        this.mapStyle = res.data.supervisionRule?.mapStyle;
      }
    },
    toDetail(item) {
      if (
        this.$dateFormat.dateFormat() < item.startTime &&
        (+item.studyType === 1 || +item.studyType === 2)
      ) {
        this.$message.warning("课程未开始");
      } else if (item.isExpired) {
        this.$message.warning("已到期，无法学习");
      } else {
        if (item.isLocked) {
          this.$message.warning("请先完成前面所有关卡后再尝试");
          return false;
        }
        this.$router.push({
          path: "/learn/course/detail",
          query: {
            id: this.taskId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" src="../asset/css/StageMap.scss" scoped></style>
