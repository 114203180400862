<template>
  <div class="courseDetail">
    <div class="info-box">
      <div>
        <div class="info-box-header">
          <div @click="$router.back()">
            <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          </div>
          <img src="../../../assets/images/learn/task-details.png" alt="" />
        </div>
        <div class="info-box-content">
          <h3>{{ taskDetail.name }}</h3>
          <div class="content-box">
            <div class="content-box-left">
              <div class="content-box-left-title">
                <div style="width: 320px">
                  <p>有效期：</p>
                  <span
                    >{{ dateFormat(taskDetail?.startTime) }} -
                    {{ dateFormat(taskDetail?.endTime) }}</span
                  >
                </div>
                <div style="width: 127px">
                  <p>创建人：</p>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="taskDetail.creator"
                    placement="top"
                  >
                    <span style="width: 57px">{{ taskDetail.creator }}</span>
                  </el-tooltip>
                </div>
                <div style="width: 300px; margin-right: 0">
                  <p>培训对象：</p>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="taskDetail.departNames"
                    placement="top"
                  >
                    <span style="width: 230px">{{
                      taskDetail.departNames
                    }}</span>
                  </el-tooltip>
                </div>
              </div>
              <div class="content-box-left-dec">
                <div class="content-box-left-dec-left">合格标准：</div>
                <div class="content-box-left-dec-right">
                  <span v-if="taskDetail?.finishedRule?.isStudyProcessChecked">
                    学习进度：大于等于{{
                      taskDetail?.finishedRule?.passStudyProcess
                    }}%；
                  </span>
                  <span v-if="taskDetail?.finishedRule?.isAllExamPass">
                    正式考核成绩：全部合格；
                  </span>
                  <span v-if="taskDetail?.finishedRule?.isAllHomeworkPass">
                    作业：全部提交并批阅合格；
                  </span>
                  <span v-if="taskDetail?.finishedRule?.isAllFormCommit">
                    问卷提交：全部提交。
                  </span>
                </div>
              </div>
              <div class="content-box-left-dec">
                <div class="content-box-left-dec-left">培训目的:</div>
                <div class="content-box-left-dec-right">
                  {{ taskDetail.description }}
                </div>
              </div>
            </div>
            <div class="content-box-right">
              <div class="progress-container">
                <el-progress
                  type="circle"
                  class="custom-circle-progress"
                  color="#1A72FF"
                  :percentage="taskDetail.studyProcess ?? 0"
                  :width="112"
                  :stroke-width="12"
                ></el-progress>
                <div class="progress-text">
                  <div class="progress-text-data">
                    <span class="number">{{
                      taskDetail.studyProcess ?? 0
                    }}</span
                    ><span class="percentage">%</span>
                  </div>
                  <p>总进度</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tree">
      <!-- <div class="tab">
        <p
          class="item"
          :class="{ active: tabIndex === item.id }"
          @click="jump(item.id)"
          v-for="item in tabList"
          :key="item.id"
        >
          {{ item.name }}
        </p>
      </div> -->
      <div class="hid-scroll-bar">
        <div class="scroll-box" id="scroll-box">
          <div class="catalogue" id="anchor2">
            <p class="title">课程目录</p>
            <tree :data="treeData" @clickNode="clickNode" />
          </div>
        </div>
      </div>
    </div>
    <facedialog
      v-if="verification"
      :confirmdialog="confirmdialog"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="1"
      :is_course_image_export="true"
      :pauseFrame="true"
      :businessId="businessId"
      :taskId="+taskId"
    ></facedialog>
    <offlineTrainingDialog
      v-if="dialogVisible.show"
      :offlineTrainInfo="offlineTrainInfo"
      :dialogVisible="dialogVisible"
    ></offlineTrainingDialog>
  </div>
</template>

<script>
import tree from "./../components/tree";
import { goLiveHome } from "@/utils/liveToHome.js";
import offlineTrainingDialog from "../components/offlineTrainingDialog";
import dayjs from "dayjs";

export default {
  components: { tree, offlineTrainingDialog },
  data() {
    return {
      dialogVisible: {
        show: false,
      },
      confirmdialog: {
        show: true,
      },
      offlineTrainInfo: {}, // 线下实训信息
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
      taskId: "",
      businessId: "",
      tabIndex: "anchor2",
      offsetTop: 0,
      treeData: [],
      tabList: [
        {
          name: "简介",
          id: "anchor1",
        },
        {
          name: "目录",
          id: "anchor2",
        },
      ],
      taskDetail: {},
      taskRuleInfo: null,
      // percentage: 77,
    };
  },
  created() {
    this.taskId = this.$route.query.id;
    this.taskDetailWithoutLogin();
    this.findTaskRule();
  },
  beforeDestroy() {},
  mounted() {},
  /* eslint-disable */
  computed: {},
  methods: {
    // 规则
    async findTaskRule() {
      await this.$api.coursePlay
        .findTaskRule(this.$route.query.id)
        .then((res) => {
          this.taskRuleInfo = res.data.supervisionRule || null;
        });
    },
    clickNode(data) {
      // type 1目录 2素材 3考试 4直播
      // if (data.type === 1) {
      //   return false;
      // }
      // 直播回放未生成不进行跳转
      if (data.isReplay === 1 && !data.replayUrl && data.contentStatus === 2) {
        return false;
      }
      // TODO 待添加直播
      if (
        this.$dateFormat.dateFormat() < this.taskDetail.startTime &&
        +this.taskDetail.studyType === 1
      ) {
        this.$message.warning("课程未开始");
        return false;
      } else if (
        this.$dateFormat.dateFormat() > this.taskDetail.endTime &&
        (+this.taskDetail.studyType === 1 || +this.taskDetail.studyType === 2)
      ) {
        this.$message.warning("已到期，无法学习");
        return false;
      }
      /**
       * isLocked：上锁
       * lockMode: 1 自由学习 2 闯关模式
       */
      if (data.isLocked) {
        this.$message.warning({
          message: "完成上一个学习内容才能开启当前学习内容哦～",
          offset: 70,
        });
        return false;
      }
      // 上锁
      // if (data.isLocked && data.type !== 1) {
      //   this.$message.warning({ message: this.multiPopTips(data.rule), offset: 70 });
      //   return false;
      // }
      if (data.type === 2) {
        // 素材
        /**
         * isCourseFaceRecognize 人脸识别 0 否 1 是
         */
        if (
          this.taskRuleInfo.isCourseFaceRecognize &&
          (!data.studyInfo || (data.studyInfo.learnRate || 0) < 100)
        ) {
          this.businessId = data.contentId;
          this.verification = true;
          this.confirmdialog.show = true;

          this.verificationAddress = {
            path: "/course/play",
            query: {
              resId: data.contentId,
              studyProcess: data.studyInfo?.learnRate || "",
              courseName: data.contentName,
              taskId: this.taskId,
              backPathName: this.$route.path,
              courseItemId: data.id,
            },
          };
        } else {
          this.$router.push({
            path: "/course/play",
            query: {
              resId: data.contentId,
              studyProcess: data.studyInfo?.learnRate || "",
              courseName: data.contentName,
              taskId: this.taskId,
              backPathName: this.$route.path,
              courseItemId: data.id,
              //source: this.$route.query.source || '',//2023-12-12-优化-素材/课程单独播放：添加source参数
              //parentId: data.parentId,//2023-12-12-优化-素材/课程单独播放：添加parentId参数
            },
          });
        }
      } else if (data.type === 3) {
        // 考试
        if (this.taskDetail.studyType === 3) {
          this.timeSlot = 0;
        }
        this.$router.push({
          path: "/examDetails",
          query: {
            examId: data.contentId, //考试ID
            bussinessId: this.taskId, //任务ID
            taskId: this.taskId,
            type: 2, //任务2
            title: this.taskDetail.name,
            pathName: this.$route.path,
            timeSlot: this.timeSlot,
            startTime: this.taskDetail.startTime,
            endTime: this.taskDetail.endTime,
          },
        });
      } else if (data.type === 6) {
        // 问卷
        this.$router.push({
          path: "/learn/questionnaire",
          query: {
            type: 2, // type：1训练营 2培训任务
            typeId: this.taskId, // typeId：训练营id或者培训任务id
            wjId: data.contentId, // wjId：问卷ID
          },
        });
      } else if (data.type === 4) {
        // 直播
        goLiveHome(this, data);
      } else if (data.type === 5) {
        // 实操作业
        this.$router.push({
          path: "/operationHomeWork",
          query: {
            taskId: this.taskId,
            id: data.contentId, // 作业id
          },
        });
      } else if (data.type === 7) {
        //练习
        this.$router.push({
          path: "/pattern",
          query: {
            businessId: this.taskId,
            practiceId: data.contentId, // 练习id
            businessName: data.contentName, //练习名称
          },
        });
      } else if (data.type === 8) {
        // 线下实训
        let offlineTrainInfo = {};
        offlineTrainInfo = {
          ...data.offlineTrainInfo,
          name: data.contentName,
          id: data.contentId,
        };
        this.offlineTrainInfo = offlineTrainInfo;
        this.dialogVisible.show = true;
      }
    },
    multiPopTips(stageRules) {
      /**
       * 开启了阶段内上锁，但是没设置条件
       */
      if (
        stageRules.isLockCourse &&
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        this.$message.warning({
          message: "完成上一个学习内容才能开启当前学习内容哦～",
          offset: 70,
        });
        return false;
      }
      /**
       * 设置了上锁并设置了条件
       */
      if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，问卷提交，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%`;
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为考试及格`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为作业通过`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，问卷提交，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，作业通过`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为问卷提交，作业提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，问卷提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，作业提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，问卷提交，作业提交";
      }
    },
    // 初始化详情
    async taskDetailWithoutLogin() {
      const params = {
        id: this.taskId || null,
      };
      await this.$api.learn
        .taskDetailWithoutLogin({ params })
        .then(async (res) => {
          if (!res.data && res.message === "用户尚未添加到该培训任务") {
            this.$router.go(-1);
            return false;
          }
          if (res.data) {
            this.taskDetail = res.data;
            this.treeData = res.data.taskItemVoList || [];
            if(res.data.type==4){
              this.treeData = res.data.taskItemVoList[0].children || []
            }
            /**
             * studyType 学习周期类型 1区间 2购买后参数 3永久有效"
             * （长期有效默认打开的是目录/项目周期开始前是简介，开始后是目录）
             */
            if (this.taskDetail.studyType === 3) {
              this.tabIndex = "anchor2";
            }
            if (
              (this.taskDetail.studyType === 1 ||
                this.taskDetail.studyType === 2) &&
              new Date().getTime() <=
                new Date(this.taskDetail.startTime).getTime()
            ) {
              this.tabIndex = "anchor1";
            } else {
              this.tabIndex = "anchor2";
            }
            this.taskDetailExt();
          }
        });
    },
    /** 遍历大纲 返回学习相关信息 */
    changeTaskTree(data, data2) {
      const idToItem2Map = {};
      // 构建 data2 的哈希表，以便通过 id 查找项
      loop(data2);
      function loop(node) {
        node.forEach((item2) => {
          idToItem2Map[item2.id] = item2;
          if (item2.children && item2.children.length) {
            loop(item2.children);
          }
        });
      }

      console.log("idToItem2Map", idToItem2Map);
      /* eslint-disable */
      // 递归地更新 data2 中的项
      function updateData2(data) {
        data.forEach((item) => {
          if (idToItem2Map.hasOwnProperty(item.id)) {
            const item2 = idToItem2Map[item.id];
            item2.studyProcess = item.studyProcess;
            item2.studyInfo = item.studyInfo;
            item2.isLocked = item.isLocked;
            item2.submitStatus = item.submitStatus;
            item2.courseId = item.courseId;
            item2.contentStatus = item.contentStatus;
          }
          if (item.children && item.children.length) {
            item.children.forEach(child=>{
              child.isParentId = item.id;
            })
            updateData2(item.children);
          }
        });
      }
      // 开始递归更新
      updateData2(data);
      console.log("data2", data2);
      this.treeData = data2;
    },
    // 二次渲染任务详情 返回学习
    taskDetailExt() {
      const params = {
        id: this.taskId,
      };
      this.$api.learn.taskDetailExts({ params }).then((res) => {
        if (res.data) {
          this.taskDetail = {
            ...this.taskDetail,
            learnTotalTime: res.data.learnTotalTime,
            courseNum: res.data.courseNum || 1,
            studyProcess: res.data.studyProcess,
            startTime: res.data.startTime ?? this.taskDetail.startTime,
            endTime: res.data.endTime ?? this.taskDetail.endTime,
            lockCourseRate: res.data.lockCourseRate,
            lockExamCondition: res.data.lockExamCondition,
            isFormCommit: res.data.isFormCommit,
            finishedRule: res.data?.ruleDto?.finishedRule,
          };
          let tree = [];
          for (let key in res.data.itemMap) {
            tree.push(res.data.itemMap[key]);
          }
          console.log("tree", tree);

          this.changeTaskTree(tree, this.treeData);
          console.log("this.treeData", this.treeData);
        }
      });
    },
    jump(id) {
      this.tabIndex = id;
      // const box = document.getElementById('scroll-box')
      // let scrollItem = document.getElementById(id)
      // // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
      // console.log(scrollItem, 'scrollItem.offsetTop')
      // this.offsetTop = scrollItem?.offsetTop;
      // console.log(box, 'box====', this.offsetTop)
      // box.scrollTo({
      //   top: scrollItem.offsetTop,
      //   behavior: "smooth",
      // });
    },
    scroll() {
      const box = document.getElementById("scroll-box");
      console.log(box.scrollTop, "box");
      console.log(this.offsetTop, "offsetTop==");
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > box.scrollTop) {
        this.offsetTop = 0;
        return;
      }
      let totalH = 0;
      this.tabList.some((anchor) => {
        let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块

        totalH = totalH + scrollItem.clientHeight;
        console.log(box.scrollTop, "box.scrollTop");
        console.log(totalH, "totalH===");
        let judge = box.scrollTop < totalH;
        console.log(judge, "judge====");
        if (judge) {
          this.tabIndex = anchor.id;
          return true;
        }
      });
    },
    format(val) {
      return `<div><div><span>${val}</span>% </div>
      <div>总进度</div></div>`;
    },
    dateFormat(time) {
      return time ? dayjs(time).format("YYYY.MM.DD HH:mm") : "";
    },
  },
};
</script>
<style
  lang="scss"
  src="../../courseCenter/assets/css/detail.scss"
  scoped
></style>
<style lang="scss" src="../asset/css/courseDetail.scss" scoped></style>
<style lang="scss" scoped>
.info-box {
  width: 100%;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 57px 0 150px;
  border-radius: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  &-header {
    display: flex;
    align-items: center;
    position: relative;
    left: -32px;
    margin-bottom: 24px;
    .backIcon {
      font-size: 26px;
      margin-right: 8px;
      cursor: pointer;
    }
    img {
      width: 85px;
      height: 21px;
    }
  }
  &-content {
    width: 1200px;
    padding: 24px 32px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 16px;
    > h3 {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      font-family: PingFangSC-Medium, PingFang SC;
      border-bottom: 1px solid #dfe5f1;
    }
    .content-box {
      display: flex;
      justify-content: space-between;
      &-left {
        flex: 1;
        &-title {
          width: 100%;
          display: flex;
          padding-top: 24px;
          align-items: center;
          div {
            p {
              width: 70px;
            }
            span {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            margin-right: 33px;
            position: relative;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            display: flex;
            color: #657d9c;
            &::after {
              content: "";
              width: 1px;
              height: 8px;
              background: #d9d9d9;
              position: absolute;
              right: -16px;
              top: 6px;
            }
            &:last-child::after {
              display: none;
            }
            span {
              color: #08224d;
            }
          }
        }
        &-dec {
          // padding-top: 12px;
          padding-top: 24px;
          display: flex;
          &-left {
            width: 70px;
            line-height: 22px;
            color: #657d9c;
          }
          &-right {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            line-height: 22px;
            color: #08224d;
          }
        }
      }
      &-right {
        width: 336px;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 12px;

        .progress-container {
          position: relative;
          width: 112px;
          height: 112px;
        }

        .progress-text {
          position: absolute;
          width: 88px;
          height: 88px;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &-data {
            height: 32px;
            .number {
              font-size: 28px;
              color: #1a72ff;
              font-weight: 500;
              font-family: "BEBAS";
            }
            .percentage {
              font-weight: 12px;
              color: #999999;
              font-family: "BEBAS";
            }
          }
          > p {
            margin-top: 4px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
