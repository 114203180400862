<template>
  <el-dialog class="mapInfoDialog" title="地图说明" :visible.sync="dialogVisible.show">
    <div class="stepBox">
      <div class="stepItem" v-for="item in list" :key="item.id">
        <p class="title">【{{ item.stageName }}】</p>
        <p class="stageDescription val">{{ item.stageDescription }}</p>
      </div>
      <div class="standard">
        <p class="title">【合规标准】</p>
        <p class="val">
          <!-- 
            isStudyProcessChecked:是否勾选学习进度  0未勾选  1勾选
            passStudyProcess:及格学习进度百分比
            isAllExamPass:正式考核成绩是否全部合格，0 未设置，1 设置
            isAllFormCommit:表单是否全部提交，0 未设置，1 设置
            isAllHomeworkPass:练习是否全部提交，0 未设置，1 设置
           -->
          <template v-if="taskRule.isStudyProcessChecked">学习进度完成{{taskRule.passStudyProcess || 0}}%</template>
          <template v-if="taskRule.isAllExamPass">；考试合格</template>
          <template v-if="taskRule.isAllFormCommit">；完成问卷</template>
          <template v-if="taskRule.isAllHomeworkPass">；完成练习</template>
        </p>
      </div>
      <div class="award">
        <p class="title">【合格奖励】</p>
        <div class="val">
          <p v-if="taskRule.credit">{{ taskRule.credit }}学分</p>
          <p v-if="taskRule.certName">{{ taskRule.certName }}</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props:{
    dialogVisible:{
      type:Object,
      default:() => {return {
        show:false
      }}
    },
    list:{
      type:Array,
      default:() => {
        return []
      }
    },
    taskRule:{
      type:Object,
      default:() => {return {}}
    }
  }
}
</script>
<style lang="scss" scoped>
.stepBox{
  .stepItem,.standard,.award{
    margin-bottom: 8px;
  }
  .title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin-bottom: 2px;
  }
  .val{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
}
::v-deep{
  .el-dialog{
    width: 570px;
    border-radius: 10px;
    .el-dialog__header{
      text-align: center;
      padding-top: 24px;
      .el-dialog__title{
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 34px;
      }
    }
    .el-dialog__body{
      padding-top: 0;
    }
  }
}
</style>